import React, { useState } from "react";

import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";
import SaveButton from "../../components/Buttons/SaveButton";
import MainTable from "../../components/MainTable";
import { useRecoilState } from "recoil";
import {
  dialogState,
  handleErrorState,
  snackBarState,
  loadingOverlayState,
} from "../../GlobalAtoms";
import { Chip, LinearProgress, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";


const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


// @material-ui components

const ClientEdit = (props: any) => {
  const classes = useStyles();

  const [values, setValue] = useState({
    id: props.editDialogData.id,
    name: props.editDialogData.name,
    initials: props.editDialogData.initials,
    corporate_name: props.editDialogData.corporate_name,
    tax_id: props.editDialogData.tax_id,
    contact: props.editDialogData.contact,
    phone: props.editDialogData.phone,
    address: props.editDialogData.address,
    number: props.editDialogData.number,
    address2: props.editDialogData.address2,
    district: props.editDialogData.district,
    city: props.editDialogData.city,
    province: props.editDialogData.province,
    postal_code: props.editDialogData.postal_code,
    coin_is_enabled: props.editDialogData.coin_is_enabled,
    coin_delivery_fee: props.editDialogData.coin_delivery_fee,
    coin_per_thousand_fee: props.editDialogData.coin_per_thousand_fee,
    cash_is_enabled: props.editDialogData.cash_is_enabled,
    cash_delivery_fee: props.editDialogData.cash_delivery_fee,
    is_enabled: props.editDialogData.is_enabled,
    country: props.editDialogData.country,
  });

  const [customers, setCustomer] = useState(props.editDialogData.customers);


  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  
  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/companies/${props.editDialogData.id}`;
    const headers = getHeaders();


    const dataSave = JSON.stringify({
      company: {
        id: values.id,
        name: values.name,
        initials: values.initials,
        corporate_name: values.corporate_name,
        tax_id: values.tax_id,
        contact: values.contact,
        phone: values.phone,
        address: values.address,
        number: values.number,
        address2: values.address2,
        district: values.district,
        city: values.city,
        province: values.province,
        postal_code: values.postal_code,
        coin_is_enabled: values.coin_is_enabled,
        coin_delivery_fee: values.coin_delivery_fee,
        coin_per_thousand_fee: values.coin_per_thousand_fee,
        cash_is_enabled: values.cash_is_enabled,
        cash_delivery_fee: values.cash_delivery_fee,
        is_enabled: values.is_enabled,
        country: values.country,
      }
    });

    axios
      .put(url, dataSave, { headers })
      .then((response) => {
        const { data } = props;
        data[props.editKey] = {
          id: values.id,
          name: values.name,
          initials: values.initials,
          corporate_name: values.corporate_name,
          tax_id: values.tax_id,
          contact: values.contact,
          phone: values.phone,
          address: values.address,
          number: values.number,
          address2: values.address2,
          district: values.district,
          city: values.city,
          province: values.province,
          postal_code: values.postal_code,
          coin_is_enabled: values.coin_is_enabled,
          coin_delivery_fee: values.coin_delivery_fee,
          coin_per_thousand_fee: values.coin_per_thousand_fee,
          cash_is_enabled: values.cash_is_enabled,
          cash_delivery_fee: values.cash_delivery_fee,
          is_enabled: values.is_enabled,
          country: values.country,
        };

        props.setData([...data]);

        setSnackBar({
          open: true,
          type: "success",
          message: 'Cliente editado com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  return (
    <>
      <FormWrapper onSubmit={(event) => store(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Nome da Empresa"}
              name={"name"}
              id={"name"}
              onChange={handleChange("name")}
              value={values.name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Sigla"}
              name={"initials"}
              id={"initials"}
              onChange={handleChange("initials")}
              value={values.initials}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Razão Social"}
              name={"corporate_name"}
              id={"corporate_name"}
              onChange={handleChange("corporate_name")}
              value={values.corporate_name}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'99.999.999/9999-99'}
              name={"tax_id"}
              id={"tax_id"}
              onChange={handleChange("tax_id")}
              value={values.tax_id}
            >
              <TextFieldWrapper label={"CNPJ"} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Responsável"}
              name={"contact"}
              id={"contact"}
              onChange={handleChange("contact")}
              value={values.contact}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'(99) 99999-9999'}
              name={"phone"}
              id={"phone"}
              onChange={handleChange("phone")}
              value={values.phone}
            >
              <TextFieldWrapper label={"Telefone"} required />
            </InputMaskWrapper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Endereço:</h4>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Endereço"}
              name={"address"}
              id={"address"}
              onChange={handleChange("address")}
              value={values.address}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Número"}
              name={"number"}
              id={"number"}
              onChange={handleChange("number")}
              value={values.number}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Complemento"}
              name={"address2"}
              id={"address2"}
              onChange={handleChange("address2")}
              value={values.address2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Bairro"}
              name={"district"}
              id={"district"}
              onChange={handleChange("district")}
              value={values.district}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Cidade"}
              name={"city"}
              id={"city"}
              onChange={handleChange("city")}
              value={values.city}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'aa'}
              name={"province"}
              id={"province"}
              onChange={handleChange("province")}
              value={values.province}
            >
              <TextFieldWrapper label={"UF"} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'99.999-999'}
              name={"postal_code"}
              id={"postal_code"}
              onChange={handleChange("postal_code")}
              value={values.postal_code}
            >
              <TextFieldWrapper label={"CEP"} required />
            </InputMaskWrapper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Serviços Disponíveis</h4>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CheckboxWrapper
                  label={"Aquisição de Troco"}
                  name={"coin_is_enabled"}
                  id={"coin_is_enabled"}
                  onChange={handleChange("coin_is_enabled")}
                  value={values.coin_is_enabled}
                  checked={values.coin_is_enabled}            
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Valor do Frete (R$)"}
                  name={"coin_delivery_fee"}
                  id={"coin_delivery_fee"}
                  onChange={handleChange("coin_delivery_fee")}
                  value={values.coin_delivery_fee}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Custo do Milheiro (R$)"}
                  name={"coin_per_thousand_fee"}
                  id={"coin_per_thousand_fee"}
                  onChange={handleChange("coin_per_thousand_fee")}
                  value={values.coin_per_thousand_fee}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CheckboxWrapper
                  label={"Recolhimento de Cédulas"}
                  name={"cash_is_enabled"}
                  id={"cash_is_enabled"}
                  onChange={handleChange("cash_is_enabled")}
                  value={values.cash_is_enabled}
                  checked={values.cash_is_enabled}            
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Valor do Frete (R$)"}
                  name={"cash_delivery_fee"}
                  id={"cash_delivery_fee"}
                  onChange={handleChange("cash_delivery_fee")}
                  value={values.cash_delivery_fee}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          {isSubmitting && <LinearProgress />}
          <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          <h4>Usuários</h4>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Nome</TableCell>
                  <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">E-mail</TableCell>
                  <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Telefone</TableCell>
                  <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer: any) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{customer.name}</TableCell>
                      <TableCell align="center">{customer.email}</TableCell>
                      <TableCell align="center">{customer.phone}</TableCell>
                      <TableCell align="center">
                        {customer.manager === 1 ?
                          'Administrador'
                        :
                          'Comum'
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </FormWrapper>
    </>
  );
};

export default ClientEdit;
