import React from "react";

// @material-ui components
import { Button, withStyles } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import orange from "@material-ui/core/colors/orange";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const EditButton: React.FC<{ children?: string; onClick: () => void }> = (
  props
) => (
  <ColorButton variant="contained" size="small" {...props}>
    <Edit style={{ fontSize: 18 }} /> {props.children}
  </ColorButton>
);

export default EditButton;
