import React, { useState } from "react";

import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import SaveButton from "../../components/Buttons/SaveButton";
import { useRecoilState } from "recoil";
import {
  dialogState,
  handleErrorState,
  snackBarState,
  loadingOverlayState,
} from "../../GlobalAtoms";
import { makeStyles } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";


const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


// @material-ui components

const EmailEdit = (props: any) => {
  const classes = useStyles();

  const [values, setValue] = useState({
    id: props.editDialogData.id,
    name: props.editDialogData.name,
    email: props.editDialogData.email,
  });


  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  
  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/emails/${props.editDialogData.id}`;
    const headers = getHeaders();


    const dataSave = JSON.stringify({
      id: values.id,
      name: values.name,
      email: values.email,
    });

    axios
      .put(url, dataSave, { headers })
      .then((response) => {
        const { data } = props;
        data[props.editKey] = {
          id: values.id,
          name: values.name,
          email: values.email,
        };

        props.setData([...data]);

        setSnackBar({
          open: true,
          type: "success",
          message: 'E-mail editado com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  }

  return (
    <>
      <FormWrapper onSubmit={(event) => store(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Nome"}
              name={"name"}
              id={"name"}
              onChange={handleChange("name")}
              value={values.name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"E-mail"}
              name={"email"}
              id={"email"}
              onChange={handleChange("email")}
              value={values.email}
              type="email"
              required            
            />
          </Grid>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
    </>
  );
};

export default EmailEdit;
