import React from "react";

import MUIDataTable from "mui-datatables";

// @material-ui components
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const getTheme = () =>
  createTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: grey[100],
          },
        },
      },
      MUIDataTablePagination: {
        root: {
          borderBottom: "none",
        },
      },
    },
  });

const MainTable: React.FC<{
  data: any[];
  columns: {}[];
}> = (props: any) => (
  <MuiThemeProvider theme={getTheme()}>
    <MUIDataTable
      {...props}
      title={<></>}
      options={{
        responsive: "standard",
        elevation: 0,
        selectableRows: "none",
        filterType: "textField",
        textLabels: {
          body: {
            noMatch: "Nenhum resultado encontrado",
            toolTip: "Ordenar",
            columnHeaderTooltip: column => `Orderne por ${column.label}`
          },
          pagination: {
            next: "Próxima",
            previous: "Anterior",
            rowsPerPage: "Por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Procurar",
            downloadCsv: "Download CSV",
            print: "Imprimir",
            viewColumns: "Ver colunas",
            filterTable: "Filtrar",
          },
          filter: {
            all: "TODOS",
            title: "FILTROS",
            reset: "RESET",
          },
          viewColumns: {
            title: "Exibir colunas",
            titleAria: "Exibir/Esconder colunas",
          },
          selectedRows: {
            text: "linha(s) selecionadas",
            delete: "Deletar",
            deleteAria: "Deletar linhas selecionadas",
          },
        }
      }}
    />
  </MuiThemeProvider>
);

export default MainTable;
