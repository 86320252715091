import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import axios from "axios";

import { Formik, Form, Field } from "formik";

import { useRecoilState } from "recoil";

// @material-ui components
import {
  Divider,
  CssBaseline,
  Hidden,
  Grid,
  Box,
} from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Check as CheckIcon,
  Mail as MailIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import { blue, indigo, red } from "@material-ui/core/colors";

import DialogWrapper from "../components/Layouts/DialogWrapper";
import TextFieldWrapper from "../components/Form/TextFieldWrapper";
import AsyncLocalStorage from "../helpers/AsyncLocalStorage";
import { settings, getHeaders, locale } from "../settings";

import { dialogState } from "../GlobalAtoms";
import LoginButton from "../components/Buttons/LoginButton";
import HandleChange from "../components/Form/HandleChange";
import FormWrapper from "../components/Form/FormWrapper";

interface Values {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    minHeight: "100vh",
    overflow: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
    },
  },
  contentLogin: {
    padding: theme.spacing(0, 5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 10),
    },
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      minHeight: "100vh",
      padding: theme.spacing(8),
    },
  },
  imgLeft: {
    height: "100vh",
    minHeight: "100%",
    background:
      "no-repeat center/auto 100% #444 url(https://cdn.zinnerlog.com.br/images/login-background.jpg)",
  },
  formControlLabelCheckbox: {
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    margin: "24px 0px 16px 0px",
  },
  imgContainer: {
    margin: "0px 0px 16px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "40px 0px 16px 0px",
    },
  },
  forgotPasswordContainer: {
    margin: "10px 0px 16px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 16px 0px",
    },
  },
  forgotPasswordSpan: {
    color: indigo[700],
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  socialMediaContainer: {
    margin: "0px 0px 16px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "15px 0px 16px 0px",
    },
  },
  errorLogin: {
    color: red[800],
    fontSize: 12,
    marginTop: 13,
  },
  messageForgotPassword: {
    color: blue[800],
    fontSize: 12,
    marginTop: 13,
  },
}));

const Login = () => {
  const customClasses = useStyles();
  // const [dialog, setDialogState] = useRecoilState(dialogState);
  const [errorLogin, setErrorLogin] = useState({ active: false, message: "" });
  const [messageLogin, setMessageLogin] = useState({ active: false, message: "" });
  // eslint-disable-next-line no-unused-vars
  const [errorForgotPassword, setErrorForgotPassword] = useState({active: false, message: ""});
  
  const [messageForgotPassword, setMessageForgotPassword] = useState({active: false, message: ""});

  const [errorAccessCode, setErrorAccessCode] = useState({active: false, message: ""});
  
  const [messageAccessCode, setMessageAccessCode] = useState({active: false, message: ""});

  const [loggedIn, setLoggedIn] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [accessCode, setAccessCode] = useState(false);

  const [values, setValue] = useState({
    email: null,
    code: null,
    password: null,
    email_reset_password: null,
  });
  
  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  useEffect(() => {
    const token = window.localStorage.getItem("ucode");
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  const getToken = (event: any) => {
    event.preventDefault();
    
    setForgotPassword(false);
    setMessageLogin({active: true, message: 'Gerando um token de acesso...'});
    setErrorLogin({active: false, message: ''});
    
    let headers = getHeaders();

    headers.credentials = true;

    let data = JSON.stringify({
      email: values.email,
      password: values.password,
      device_name: 'browser',
    });
      
    axios.post(settings().apiUrl + '/api/login', data, {headers})
      .then(response => {
        setAccessCode(true);
        setMessageLogin({active: false, message: ''});    
        setErrorLogin({active: false, message: ''});    
      })
    .catch((error) => {
      setErrorLogin({active: true, message: 'E-mail ou senha inválido(s)'});
      setMessageLogin({active: false, message: ''});    
    })
  }

  const login = (event: any) => {
    event.preventDefault();

    setForgotPassword(false);
    
    let headers = getHeaders();

    headers.credentials = true;

    let data = JSON.stringify({
      code: values.code,
    });
      
    axios.post(settings().apiUrl + '/api/get/token', data, {headers})
      .then(response => {
        AsyncLocalStorage('ucode', JSON.stringify(response.data)).then(function () {
          setLoggedIn(true);
        })
      })
    .catch((error) => {
      setErrorAccessCode({active: true, message: 'Código de acesso inválido'});
    })
  }

  const resetPassword = (event: any) => {
    event.preventDefault();
    
    setMessageForgotPassword({active: true, message: 'Gerando uma nova senha...'});
    setErrorForgotPassword({active: false, message: ''});
    
    let headers = getHeaders();

    headers.credentials = true;

    let data = JSON.stringify({
      email: values.email_reset_password,
    });
      
    axios.post(settings().apiUrl + '/api/reset/password', data, {headers})
      .then(response => {
        setMessageForgotPassword({active: true, message: 'Uma nova senha foi enviada para seu e-mail.'});
        setErrorForgotPassword({active: false, message: ''});
      })
    .catch((error) => {
      setMessageForgotPassword({active: false, message: ''});
      setErrorForgotPassword({active: true, message: 'E-mail inválido.'});
    })
  }

  const content = (
    <>
      {loggedIn && <Redirect to="/dashboard" />}
      <Grid container justify="center" spacing={3}>
        <Grid item xs={8} sm={7} md={6}>
          <Box textAlign="center" className={customClasses.imgContainer}>
            <img
              src="https://cdn.zinnerlog.com.br/images/logo.png"
              alt="Grupo Zinner"
              style={{ width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
      <FormWrapper onSubmit={(event) => getToken(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextFieldWrapper
              label={"E-mail"}
              name={"email"}
              id={"email"}
              onChange={handleChange("email")}
              value={values.email}
              required
            />
          </Grid>
          <Grid item xs={12}>
          <TextFieldWrapper
              label={"Senha"}
              name={"password"}
              id={"password"}
              type="password"
              onChange={handleChange("password")}
              value={values.password}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box>
              <LoginButton
                type="submit"
                icon={ExitToAppIcon}
              >
                Logar
              </LoginButton>
            </Box>
            <Box textAlign="center" className={customClasses.errorLogin}>
              {errorLogin.active && errorLogin.message}
            </Box>
            <Box textAlign="center" className={customClasses.messageForgotPassword}>
              {messageLogin.active && messageLogin.message}
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
      <Box textAlign="center" className={customClasses.forgotPasswordContainer}>
        <span
          onClick={() => {setForgotPassword(!forgotPassword); setAccessCode(false)}}
          role="button"
          tabIndex={0}
          className={customClasses.forgotPasswordSpan}
        >
          {locale.login.forgotPasswordTrigger}
        </span>
      </Box>
      {accessCode && 
        <FormWrapper onSubmit={(event) => login(event)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                label={"Código de Acesso"}
                name={"code"}
                id={"code"}
                onChange={handleChange("code")}
                value={values.code}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <LoginButton
                  type="submit"
                  icon={CheckIcon}
                >
                  Confirmar Código
                </LoginButton>
              </Box>
              <Box textAlign="center" className={customClasses.messageForgotPassword}>
                <strong>{messageAccessCode.active && messageAccessCode.message}</strong>
              </Box>
              <Box textAlign="center" className={customClasses.errorLogin}>
                {errorAccessCode.active && errorAccessCode.message}
              </Box>
            </Grid>
          </Grid>
        </FormWrapper>
      }
      {forgotPassword && 
        <FormWrapper onSubmit={(event) => resetPassword(event)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                label={"E-mail"}
                name={"email_reset_password"}
                id={"email_reset_password"}
                onChange={handleChange("email_reset_password")}
                value={values.email_reset_password}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <LoginButton
                  type="submit"
                  icon={MailIcon}
                >
                  Recuperar Senha
                </LoginButton>
              </Box>
              <Box textAlign="center" className={customClasses.messageForgotPassword}>
                <strong>{messageForgotPassword.active && messageForgotPassword.message}</strong>
              </Box>
              <Box textAlign="center" className={customClasses.errorLogin}>
                {errorForgotPassword.active && errorForgotPassword.message}
              </Box>
            </Grid>
          </Grid>
        </FormWrapper>
      }
    </>
  );

  return (
    <div className={customClasses.root}>
      <CssBaseline />
      <main className={customClasses.content}>
        <Hidden smUp implementation="css">
          <Box className={customClasses.contentLogin} />
          {content}
        </Hidden>
        <Hidden xsDown implementation="css">
          <Grid container spacing={0}>
            <Grid item sm={5} md={7} xl={8} className={customClasses.imgLeft} />
            <Grid
              item
              sm={7}
              md={5}
              xl={4}
              className={customClasses.contentLogin}
            >
              {content}
            </Grid>
          </Grid>
        </Hidden>
        <DialogWrapper />
      </main>
    </div>
  );
};

export default Login;
