import React from "react";

// @material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  CircularProgress,
  makeStyles,
  SvgIconTypeMap,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[700],
    },
    width: "100%",
    padding: "8px 12px !important",
  },
}))(Button);

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 18,
    marginRight: 10,
  },
  loadingIcon: {
    animationDuration: "550ms",
    color: grey[600],
    marginRight: 10,
  },
}));

const LoginButton: React.FC<{
  children?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  disabled?: boolean;
  type?: string;
  onClick?: () => void;
}> = (props: any) => {
  const classes = useStyles();

  return (
    <ColorButton variant="contained" {...props}>
      {props.icon && !props.disabled && <props.icon className={classes.icon} />}
      {props.disabled && (
        <CircularProgress
          className={classes.loadingIcon}
          size={18}
          disableShrink
        />
      )}
      {props.children && <> {props.children}</>}
    </ColorButton>
  );
};

export default LoginButton;
