import React from "react";

// @material-ui components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CheckboxWrapper = (props: any) => (
  <FormControlLabel
    control={<Checkbox color="primary" {...props} />}
    label={props.label}
  />
);

export default CheckboxWrapper;
