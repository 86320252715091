import React from "react";

// @material-ui components
import { makeStyles, Typography } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  icon: {
    position: "relative",
    top: 3,
    fontSize: 21,
  },
}));

const TitleHeader: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.root}>
      {props.children}
    </Typography>
  );
};

export default TitleHeader;
