import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, makeStyles, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
// import EditButton from "../../components/Buttons/EditButton";
import AddButton from "../../components/Buttons/AddButton";
import DeleteButton from "../../components/Buttons/DeleteButton";

import ClientAdd from "./ClientAdd";
import ClientEdit from "./ClientEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import EditButton from "../../components/Buttons/EditButton";
import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


const Client: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/companies`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setHandleError({ error: true, response: error.response })
      });
  }, []);

  const openEditDialog = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/companies/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Editar Cliente',
          content: <ClientEdit
            editKey={indexKey}
            editDialogData={{...response.data.data}}
            setData={setData}
            data={data}
          />,
          maxWidth: "xl",
          transition: "regular",
        })
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setHandleError({ error: true, response: error.response })
      });


  };

  const deleteItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/companies/toggle/enabled/${rowData.id}`;
    let headers = getHeaders();

    let is_enabled = 1;

    if(rowData.is_enabled === 1) {
      is_enabled = 0;
    }

    const body = JSON.stringify({
      is_enabled: is_enabled,
    });

    await axios
      .put(url, body, { headers })
      .catch((error) => {
        setHandleError({ error: true, response: error.response })
      });

    url = `${settings().apiUrl}/api/companies`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Status do cliente editado com sucesso.',
        });
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  const addDialog = <ClientAdd setData={setData} data={data} />;

  const columns = [
    {
      label: 'Nome',
      name: "name",
    },
    {
      label: "Sigla",
      name: "initials",
    },
    {
      label: 'Contato',
      name: "contact",
    },
    {
      label: 'Telefone',
      name: "phone",
    },
    {
      label: 'Endereço',
      name: "address",
    },
    {
      label: 'N.º',
      name: "number",
    },
    {
      label: 'Bairro',
      name: "district",
    },
    {
      label: 'Cidade',
      name: "city",
    },
    {
      label: 'Status',
      name: "is_enabled",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value == 1 ? 
              <Chip label="Ativo" className={classes.activeChip} />
              : 
              <Chip label="Inativo" className={classes.inactiveChip} />
            }
          </>
        ),
      },
    },
    {
      label: ' ',
      name: "is_enabled",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <DeleteButton
              onClick={() =>
                setConfirmationDialogState({
                  ...confirmationDialog,
                  open: true,
                  question: `Você tem certeza que deseja ${value == 1 ? 'desativar' : 'ativar'} o cliente ${
                    data[tableMeta.currentTableData[tableMeta.rowIndex].index].name
                  }?`,
                  action: () => deleteItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                  transition: "default",
                })
              }
              active={value}
            />
          </>
        ),
      },
    },
    {
      label: 'Editar',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <EditButton
            onClick={() =>
              openEditDialog(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
            }
          />
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Clientes'}</TitleHeader>}
          button={
            <AddButton
              onClick={() =>
                setDialogState({
                  ...dialog,
                  open: true,
                  title: 'Adicionar Cliente',
                  content: addDialog,
                  maxWidth: "xl",
                  transition: "regular",
                })
              }
            >
              {'Adicionar Cliente'}
            </AddButton>
          }
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Client;
