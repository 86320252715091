import {
  AccountBox as AccountBoxIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";

// Pages
import Client from "./pages/Client/Client";
import Store from "./pages/Store/Store";
import Email from "./pages/Email/Email";
import Selling from "./pages/Order/Selling";
import Acquiring from "./pages/Order/Acquiring";
import Exception from "./pages/Order/Exception";
import Account from "./pages/User/Account";
import User from "./pages/User/User";

/**
 * Sets all routes used in dashboard. All routes comes with title, icon and the page's component.
 * Display parameter can be set as 'menu' for main left menu or 'avatar' for avatar's menu
 * All routes for dashboard should be set here, even if it won't be displayed in any menu
 */

const dashboardRoutes = [
  {
    path: "/account",
    label: 'Minha Conta',
    icon: AccountBoxIcon,
    component: Account,
    layout: "/dashboard",
    display: "avatar",
  },
  {
    menuGroup: "pedidos",
    label: "Pedidos",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        divider: true,
      },
      {
        path: "/selling",
        label: "Aquisição de Troco",
        component: Selling,
        params: "/:monthParam?/:yearParam?",
      },
      {
        path: "/acquring",
        label: "Recolhimento de Cédulas",
        component: Acquiring,
        params: "/:monthParam?/:yearParam?",
      },
      {
        path: "/exception",
        label: "Pedido de Emergência",
        component: Exception,
      },
    ],
  },
  {
    menuGroup: "clientes",
    label: "Clientes",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        divider: true,
      },
      {
        path: "/clients",
        label: "Lista de Clientes",
        component: Client,
      },
      {
        path: "/stores",
        label: "Lista de Lojas",
        component: Store,
      },
    ],
  },
  {
    menuGroup: "configuracao",
    label: "Configurações",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        divider: true,
      },
      {
        path: "/emails",
        label: "E-mails de Alerta",
        component: Email,
      },
      {
        path: "/users",
        label: "Lista de usuários",
        component: User,
      },
    ],
  },
];

export default dashboardRoutes;
