import React from "react";

// @material-ui components
import { Button, withStyles } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import green from "@material-ui/core/colors/green";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const ApproveButton: React.FC<{
  onClick: () => void;
}> = (props) => (
  <ColorButton variant="contained" size="small" {...props}>
    <Check style={{ fontSize: 18 }} /> {props.children}
  </ColorButton>
);

export default ApproveButton;
