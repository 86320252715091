import React, { useState, useEffect } from "react";

// @material-ui components
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Grow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";

import { useRecoilState } from "recoil";

import { dialogState } from "../../GlobalAtoms";

const dialogStyle = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: spacing(1),
      top: spacing(1),
      color: palette.grey[500],
    },
  })
);

const Transition = React.forwardRef(
  (props: TransitionProps, ref: React.Ref<unknown>) => (
    <Grow ref={ref} {...props} />
  )
);

const DialogWrapper = () => {
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [fullScreen, setFullScreen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (dialog.fullScreen && isMobile) {
      setFullScreen(true);
    }
  }, [dialog]);

  const customClasses = dialogStyle();

  return (
    <>
      {dialog.open && (
        <Dialog
          open={dialog.open}
          fullWidth={dialog.fullWidth}
          maxWidth={dialog.maxWidth}
          scroll={dialog.scroll}
          fullScreen={fullScreen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Transition}
        >
          <DialogTitle disableTypography className={customClasses.root}>
            <Typography variant="h6">{dialog.title}</Typography>
            <IconButton
              aria-label="close"
              className={customClasses.closeButton}
              onClick={() =>
                setDialogState({
                  open: false,
                  title: false,
                  content: false,
                  fullWidth: true,
                  maxWidth: "sm",
                  scroll: undefined,
                  fullScreen: true,
                  transition: "default",
                })
              }
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{dialog.content}</DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DialogWrapper;
