import React from "react";

// @material-ui components
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingSkeleton from "./LoadingSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 3),
    },
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
}));

const ContentWrapper: React.FC<{
  loading: boolean;
}> = (props) => {
  const classes = useStyles();
  if (props.loading === true) {
    return <LoadingSkeleton />;
  }
  return <Box className={classes.root}>{props.children}</Box>;
};

export default ContentWrapper;
