import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { Button, LinearProgress, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";

const ChangeDate = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [values, setValue] = useState({
    confirmed: 1,
    delivery_date: '',
    delivery_date_observations: null,
  });

  const [error, setError] = useState(false);

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();
    setError(false)
    
    setSubmitting(true);
    setLoadingOverlayState(true);
    setDialogState({
      ...dialog,
      open: false,
    });
    
    const url = `${settings().apiUrl}/api/orders/change/date/${props.editDialogData.id}`;
    const headers = getHeaders();
    const body = JSON.stringify(values);

    axios
      .put(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        setLoadingOverlayState(false);
        const { data } = props;
        data[props.editKey] = { ...response.data.data };

        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Data de entrega confirmada com sucesso',
        });
        setSubmitting(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }

  return (
    <FormWrapper onSubmit={(event) => store(event)}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <InputMaskWrapper
            mask={'99/99/9999'}
            name={"delivery_date"}
            id={"delivery_date"}
            onChange={handleChange("delivery_date")}
            value={values.delivery_date}
          >
            <TextFieldWrapper label={"Nova Data"} placeholder={'dd/mm/aaaa'} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextFieldWrapper
            label={"Observações"}
            name={"delivery_date_observations"}
            id={"delivery_date_observations"}
            onChange={handleChange("delivery_date_observations")}
            value={values.delivery_date_observations}
            required
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <SaveButton
            type="submit"
            disabled={isSubmitting}
          />
        </Box>
      </Grid>
    </FormWrapper>
  );
};
export default ChangeDate ;
