import React from "react";

// @material-ui components
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
  },
}));

const LoadingSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.paper}>
      <Skeleton height={12} width="40%" />
      <Skeleton height={12} width="70%" />
      <Skeleton height={12} width="30%" />
      <br />
      <Skeleton height={12} width="60%" />
      <Skeleton height={12} width="40%" />
      <Skeleton height={12} width="70%" />
      <br />
      <Skeleton height={12} width="30%" />
      <Skeleton height={12} width="60%" />
      <Skeleton height={12} width="40%" />
      <br />
      <Skeleton height={12} width="70%" />
      <Skeleton height={12} width="30%" />
      <Skeleton height={12} width="60%" />
    </Box>
  );
};

export default LoadingSkeleton;
