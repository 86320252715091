import React from "react";

// @material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  textField: {
    width: "100%",
  },
});

const TextFieldWrapper = (props: any) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.textField}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

export default TextFieldWrapper;
