import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { RecoilRoot } from "recoil";

// Layout components
import Dashboard from "./layouts/Dashboard";
import Login from "./layouts/Login";

ReactDOM.render(
  <RecoilRoot>
    <Router>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/login" component={Login} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
  </RecoilRoot>,
  document.getElementById("root")
);
