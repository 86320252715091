import React from "react";

// @material-ui components
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const FormWrapper: React.FC<{
  children?: JSX.Element | JSX.Element[];
  disabled?: boolean;
  type?: string;
  onSubmit?: (event: any) => void;
}> = (props: any) => {
  const classes = useStyles();

  return (
    <form className={classes.container} autoComplete="on" {...props}>
      {props.children}
    </form>
  )
}

export default FormWrapper
