import React from "react";

// @material-ui components
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3, 0),
    },
  },
}));

const DividerWrapper: React.FC = () => {
  const classes = useStyles();
  return <Divider className={classes.root} />;
};

export default DividerWrapper;
