import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import DividerWrapper from "../../components/DividerWrapper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const AcquiringEdit = (props: any) => {
  return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Empresa"}
              name={"company_name"}
              id={"company_name"}
              value={props.editDialogData.company_name}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Serviço Solicitado"}
              name={"initials"}
              id={"initials"}
              value={'Recolhimento de Cédula'}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Loja"}
              name={"initials"}
              id={"initials"}
              value={props.editDialogData.store_initials}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Data de Preferência"}
              name={"preferred_date"}
              id={"preferred_date"}
              value={props.editDialogData.preferred_date}
              disabled
              variant="filled"            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <TextFieldWrapper
              label={"Observações"}
              name={"observations"}
              id={"observations"}
              value={props.editDialogData.observations}
              disabled
              variant="filled"            
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Dados da Loja:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Endereço"}
              name={"address"}
              id={"address"}
              value={props.editDialogData.store.address}
              disabled
              variant="filled"
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Número"}
              name={"number"}
              id={"number"}
              value={props.editDialogData.store.number}
              disabled
              variant="filled"
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Complemento"}
              name={"address2"}
              id={"address2"}
              value={props.editDialogData.store.address2}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Bairro"}
              name={"district"}
              id={"district"}
              value={props.editDialogData.store.district}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Cidade"}
              name={"city"}
              id={"city"}
              value={props.editDialogData.store.city}
              disabled
              variant="filled"
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"UF"}
              name={"province"}
              id={"province"}
              value={props.editDialogData.store.province}
              disabled
              variant="filled"
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"CEP"}
              name={"postal_code"}
              id={"postal_code"}
              value={props.editDialogData.store.postal_code}
              disabled
              variant="filled"
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Telefone"}
              name={"phone"}
              id={"phone"}
              value={props.editDialogData.store.phone}
              disabled
              variant="filled"
              required            
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Milheiros para cada moeda:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,05"}
              name={"quantity_coin_5"}
              id={"quantity_coin_5"}
              value={props.editDialogData.quantity_coin_5}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,10"}
              name={"quantity_coin_10"}
              id={"quantity_coin_10"}
              value={props.editDialogData.quantity_coin_10}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,25"}
              name={"quantity_coin_25"}
              id={"quantity_coin_25"}
              value={props.editDialogData.quantity_coin_25}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,50"}
              name={"quantity_coin_50"}
              id={"quantity_coin_50"}
              value={props.editDialogData.quantity_coin_50}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 1,00"}
              name={"quantity_coin_100"}
              id={"quantity_coin_100"}
              value={props.editDialogData.quantity_coin_100}
              disabled
              variant="filled"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Milheiros para cada cédula:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 2,00"}
              name={"quantity_cash_2"}
              id={"quantity_cash_2"}
              value={props.editDialogData.quantity_cash_2}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 5,00"}
              name={"quantity_cash_5"}
              id={"quantity_cash_5"}
              value={props.editDialogData.quantity_cash_5}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 10,00"}
              name={"quantity_cash_10"}
              id={"quantity_cash_10"}
              value={props.editDialogData.quantity_cash_10}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 20,00"}
              name={"quantity_cash_20"}
              id={"quantity_cash_20"}
              value={props.editDialogData.quantity_cash_20}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 50,00"}
              name={"quantity_cash_50"}
              id={"quantity_cash_50"}
              value={props.editDialogData.quantity_cash_50}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 100,00"}
              name={"quantity_cash_100"}
              id={"quantity_cash_100"}
              value={props.editDialogData.quantity_cash_100}
              disabled
              variant="filled"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,05</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,10</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,25</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,50</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 1,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{props.editDialogData.quantity_coin_5}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_coin_10}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_coin_25}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_coin_50}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_coin_100}</TableCell>
                <TableCell align="right">R$ {props.editDialogData.coin_value}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 2,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 5,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 10,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 20,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 50,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 100,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{props.editDialogData.quantity_cash_2}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_cash_5}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_cash_10}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_cash_20}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_cash_50}</TableCell>
                <TableCell align="center">{props.editDialogData.quantity_cash_100}</TableCell>
                <TableCell align="right">R$ {props.editDialogData.cash_value}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h4>Valores Totais:</h4>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Total em Moedas</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Total em Cédulas</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Valor Solicitado</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Valor da Entrega</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">R$ {props.editDialogData.coin_value}</TableCell>
                <TableCell align="center">R$ {props.editDialogData.cash_value}</TableCell>
                <TableCell align="center">R$ {props.editDialogData.requested_value}</TableCell>
                <TableCell align="center">R$ {props.editDialogData.cash_delivery_fee_paid}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h4>Log de Ações:</h4>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Ação</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Observações</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.editDialogData.order_logs.map((log: any) => (
                <TableRow>
                  <TableCell align="center">{log.message}</TableCell>
                  <TableCell align="center">{log.observations}</TableCell>
                  <TableCell align="center">{log.created_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
  );
};

export default AcquiringEdit;
