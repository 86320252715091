import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { MenuItem } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";

const ExceptionAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
  stores: [];
}> = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [step, setStep] = useState(1);
  const [values, setValue] = useState({
    store_id: null,
    exception_request_date: null,
    exception_delivery_date: null,
    exception_request_total: '0,00',
    exception_extra_fee: '0,00',
  });

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };


  const store = (event: any) => {
    event.preventDefault();

    setLoadingOverlayState(true);
    
    const url = `${settings().apiUrl}/api/orders/exception`;
    const headers = getHeaders();
    const body = JSON.stringify({
      store_id: values.store_id,
      exception_request_date: values.exception_request_date,
      exception_delivery_date: values.exception_delivery_date,
      exception_request_total: values.exception_request_total,
      exception_extra_fee: values.exception_extra_fee,
    });

    console.log('hey');
    axios
      .post(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        setLoadingOverlayState(false);
        const { data } = props;
        data.push(response.data.data);
        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Pedido emergencial criado com sucesso',
        });
        setDialogState({
          ...dialog,
          open: false,
        });    
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }

    return (
      <FormWrapper onSubmit={(event) => store(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Loja"}
              name={"store_id"}
              id={"store_id"}
              onChange={handleChange("store_id")}
              value={values.store_id}
              required
              select
            >
              {props.stores.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name} - {option.initials}
                </MenuItem>
              ))}
            </TextFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'99/99/9999'}
              name={"exception_request_date"}
              id={"exception_request_date"}
              onChange={handleChange("exception_request_date")}
              value={values.exception_request_date}
              required
            >
              <TextFieldWrapper label={"Data de Pedido"} placeholder={'dd/mm/aaaa'} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'99/99/9999'}
              name={"exception_delivery_date"}
              id={"exception_delivery_date"}
              onChange={handleChange("exception_delivery_date")}
              value={values.exception_delivery_date}
              required
            >
              <TextFieldWrapper label={"Data de Entrega"} placeholder={'dd/mm/aaaa'} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Total Liberado Para Pedido"}
              name={"exception_request_total"}
              id={"exception_request_total"}
              onChange={handleChange("exception_request_total")}
              value={values.exception_request_total}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Taxa Extra"}
              name={"exception_extra_fee"}
              id={"exception_extra_fee"}
              onChange={handleChange("exception_extra_fee")}
              value={values.exception_extra_fee}
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <SaveButton
              type="submit"
              disabled={isSubmitting}
            />
          </Box>
        </Grid>
      </FormWrapper>
    );
};
export default ExceptionAdd;
