import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { Button, LinearProgress, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, locale, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";
import { blue, red } from "@material-ui/core/colors";
import { ArrowBackIos, ShoppingCart } from "@material-ui/icons";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[700],
    },
    width: "100%",
    padding: "8px 12px !important",
  },
}))(Button);

const BackButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: red[600],
    "&:hover": {
      backgroundColor: red[700],
    },
    width: "100%",
    padding: "8px 12px !important",
  },
}))(Button);

const SellingAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
  company: {}[];
}> = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [step, setStep] = useState(1);
  const [values, setValue] = useState({
    company_id: 1,
    company_name: props.company.name,
    status: 0,
    store_id: null,
    preferred_date: null,
    observations: null,

    quantity_coin_5: '0,00',
    quantity_coin_10: '0,00',
    quantity_coin_25: '0,00',
    quantity_coin_50: '0,00',
    quantity_coin_100: '0,00',
    coin_value: 0,

    quantity_cash_2: '0,00',
    quantity_cash_5: '0,00',
    quantity_cash_10: '0,00',
    quantity_cash_20: '0,00',
    quantity_cash_50: '0,00',
    quantity_cash_100: '0,00',
    quantity_cash_200: '0,00',
    cash_value: 0,

    requested_value: 0,
    
    quantity_coin_5_string: '0,00',
    quantity_coin_10_string: '0,00',
    quantity_coin_25_string: '0,00',
    quantity_coin_50_string: '0,00',
    quantity_coin_100_string: '0,00',
    coin_value_string: '0,00',

    quantity_cash_2_string: '0,00',
    quantity_cash_5_string: '0,00',
    quantity_cash_10_string: '0,00',
    quantity_cash_20_string: '0,00',
    quantity_cash_50_string: '0,00',
    quantity_cash_100_string: '0,00',
    quantity_cash_200_string: '0,00',
    cash_value_string: '0,00',

    requested_value_string: '0,00',
    
    coin_delivery_fee: '0,00',
    coin_delivery_fee_paid: '0,00',
    coin_per_thousand_fee: '0,00',
    coin_per_thousand_fee_paid: '0,00',

    total: '0,00',

    accept: false,
  });

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const calculate = (event: any) => {
    event.preventDefault();

    let quantity_coin_5 = parseFloat(values.quantity_coin_5.replace(",", "."));
    let quantity_coin_10 = parseFloat(values.quantity_coin_10.replace(",", "."));
    let quantity_coin_25 = parseFloat(values.quantity_coin_25.replace(",", "."));
    let quantity_coin_50 = parseFloat(values.quantity_coin_50.replace(",", "."));
    let quantity_coin_100 = parseFloat(values.quantity_coin_100.replace(",", "."));
    let coin_value = ((quantity_coin_5 * 0.05) + (quantity_coin_10 * 0.10) + (quantity_coin_25 * 0.25) + (quantity_coin_50 * 0.50) + quantity_coin_100) * 1000;
    let quantity_cash_2 = parseFloat(values.quantity_cash_2.replace(",", "."));
    let quantity_cash_5 = parseFloat(values.quantity_cash_5.replace(",", "."));
    let quantity_cash_10 = parseFloat(values.quantity_cash_10.replace(",", "."));
    let quantity_cash_20 = parseFloat(values.quantity_cash_20.replace(",", "."));
    let quantity_cash_50 = parseFloat(values.quantity_cash_50.replace(",", "."));
    let quantity_cash_100 = parseFloat(values.quantity_cash_100.replace(",", "."));
    let quantity_cash_200 = parseFloat(values.quantity_cash_200.replace(",", "."));
    let cash_value = ((quantity_cash_2 * 2) + (quantity_cash_5 * 5) + (quantity_cash_10 * 10) + (quantity_cash_20 * 20) + (quantity_cash_50 * 50) + (quantity_cash_100 * 100) + (quantity_cash_200 * 200)) * 1000;
    let requested_value = coin_value + cash_value;

    let total_thousands = quantity_coin_5 + quantity_coin_10 + quantity_coin_25 + quantity_coin_50 + quantity_coin_100 + quantity_cash_2 + quantity_cash_5 + quantity_cash_10 + quantity_cash_20 + quantity_cash_50 + quantity_cash_100 + quantity_cash_200;

    let coin_delivery_fee = 0;
    let coin_per_thousand_fee = 0;
    let coin_delivery_fee_paid = 0;
    let coin_per_thousand_fee_paid = 0;
    props.company.stores.map((store: any) => {
      if(store.id === values.store_id) {
        if(store.coin_use_from_company === 1) {
          coin_delivery_fee = props.company.coin_delivery_fee;
          coin_delivery_fee_paid = props.company.coin_delivery_fee;
          coin_per_thousand_fee = props.company.coin_per_thousand_fee;
          coin_per_thousand_fee_paid = (total_thousands *  parseFloat(props.company.coin_per_thousand_fee.replace(",", ".")));
        } else {
          coin_delivery_fee = store.coin_delivery_fee;
          coin_delivery_fee_paid = store.coin_delivery_fee;
          coin_per_thousand_fee = store.coin_per_thousand_fee;
          coin_per_thousand_fee_paid = (total_thousands * parseFloat(store.coin_per_thousand_fee.replace(",", ".")));
        }
      }
    });

    let valuesUpdate = { ...values };
    valuesUpdate.coin_value = coin_value;
    valuesUpdate.cash_value = cash_value;
    valuesUpdate.requested_value = requested_value;
    valuesUpdate.quantity_coin_5_string = String(quantity_coin_5);
    valuesUpdate.quantity_coin_10_string = String(quantity_coin_10);
    valuesUpdate.quantity_coin_25_string = String(quantity_coin_25);
    valuesUpdate.quantity_coin_50_string = String(quantity_coin_50);
    valuesUpdate.quantity_coin_100_string = String(quantity_coin_100);
    valuesUpdate.coin_value_string = String(coin_value);
    valuesUpdate.quantity_cash_2_string = String(quantity_cash_2);
    valuesUpdate.quantity_cash_5_string = String(quantity_cash_5);
    valuesUpdate.quantity_cash_10_string = String(quantity_cash_10);
    valuesUpdate.quantity_cash_20_string = String(quantity_cash_20);
    valuesUpdate.quantity_cash_50_string = String(quantity_cash_50);
    valuesUpdate.quantity_cash_100_string = String(quantity_cash_100);
    valuesUpdate.quantity_cash_200_string = String(quantity_cash_200);
    valuesUpdate.cash_value_string = String(cash_value);
    valuesUpdate.requested_value_string = String(requested_value);
    valuesUpdate.coin_delivery_fee = String(coin_delivery_fee);
    valuesUpdate.coin_delivery_fee_paid = String(coin_delivery_fee_paid);
    valuesUpdate.coin_per_thousand_fee = String(coin_per_thousand_fee);
    valuesUpdate.coin_per_thousand_fee_paid = String(coin_per_thousand_fee_paid);
    setValue({ ...valuesUpdate });
    setStep(2);
    
  }

  const store = (event: any) => {
    event.preventDefault();

    setSubmitting(true);
    setLoadingOverlayState(true);
    setDialogState({
      ...dialog,
      open: false,
    });
    
    const url = `${settings().apiUrl}/api/orders`;
    const headers = getHeaders();
    const body = JSON.stringify({
      company_id: 1,
      customer_id: 1,
      status: 0,
      company_name: props.company.name,
      type: "selling",
      store_id: values.store_id,
      preferred_date: values.preferred_date,
      observations: values.observations,
  
      quantity_coin_5: values.quantity_coin_5,
      quantity_coin_10: values.quantity_coin_10,
      quantity_coin_25: values.quantity_coin_25,
      quantity_coin_50: values.quantity_coin_50,
      quantity_coin_100: values.quantity_coin_100,
      coin_value: values.coin_value,
  
      quantity_cash_2: values.quantity_cash_2,
      quantity_cash_5: values.quantity_cash_5,
      quantity_cash_10: values.quantity_cash_10,
      quantity_cash_20: values.quantity_cash_20,
      quantity_cash_50: values.quantity_cash_50,
      quantity_cash_100: values.quantity_cash_100,
      quantity_cash_200: values.quantity_cash_200,
      cash_value: values.cash_value,
  
      requested_value: values.requested_value,
      
      coin_delivery_fee: values.coin_delivery_fee,
      coin_delivery_fee_paid: values.coin_delivery_fee_paid,
      coin_per_thousand_fee: values.coin_per_thousand_fee,
      coin_per_thousand_fee_paid: values.coin_per_thousand_fee_paid,
  
      total: values.total,
    });

    axios
      .post(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        setLoadingOverlayState(false);
        const { data } = props;
        data.push(response.data.data);
        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Pedido criado com sucesso',
        });
        setSubmitting(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }

  if(step === 1) {
    return (
      <FormWrapper onSubmit={(event) => calculate(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Empresa"}
              name={"company_name"}
              id={"company_name"}
              onChange={handleChange("company_name")}
              value={values.company_name}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Serviço Solicitado"}
              name={"initials"}
              id={"initials"}
              onChange={handleChange("initials")}
              value={'Aquisição de Troco'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
              label={"Loja"}
              name={"store_id"}
              id={"store_id"}
              onChange={handleChange("store_id")}
              value={values.store_id}
              required
              select
            >
              {props.company.stores.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name} - {option.initials}
                </MenuItem>
              ))}
            </TextFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Data de Preferência"}
              name={"preferred_date"}
              id={"preferred_date"}
              onChange={handleChange("preferred_date")}
              value={values.preferred_date}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <TextFieldWrapper
              label={"Observações"}
              name={"observations"}
              id={"observations"}
              onChange={handleChange("observations")}
              value={values.observations}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Informe quantos milheiros deseja para cada moeda:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,05"}
              name={"quantity_coin_5"}
              id={"quantity_coin_5"}
              onChange={handleChange("quantity_coin_5")}
              value={values.quantity_coin_5}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,10"}
              name={"quantity_coin_10"}
              id={"quantity_coin_10"}
              onChange={handleChange("quantity_coin_10")}
              value={values.quantity_coin_10}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,25"}
              name={"quantity_coin_25"}
              id={"quantity_coin_25"}
              onChange={handleChange("quantity_coin_25")}
              value={values.quantity_coin_25}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,50"}
              name={"quantity_coin_50"}
              id={"quantity_coin_50"}
              onChange={handleChange("quantity_coin_50")}
              value={values.quantity_coin_50}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 1,00"}
              name={"quantity_coin_100"}
              id={"quantity_coin_100"}
              onChange={handleChange("quantity_coin_100")}
              value={values.quantity_coin_100}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Informe quantos milheiros deseja para cada cédula:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 2,00"}
              name={"quantity_cash_2"}
              id={"quantity_cash_2"}
              onChange={handleChange("quantity_cash_2")}
              value={values.quantity_cash_2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 5,00"}
              name={"quantity_cash_5"}
              id={"quantity_cash_5"}
              onChange={handleChange("quantity_cash_5")}
              value={values.quantity_cash_5}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 10,00"}
              name={"quantity_cash_10"}
              id={"quantity_cash_10"}
              onChange={handleChange("quantity_cash_10")}
              value={values.quantity_cash_10}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 20,00"}
              name={"quantity_cash_20"}
              id={"quantity_cash_20"}
              onChange={handleChange("quantity_cash_20")}
              value={values.quantity_cash_20}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <ColorButton variant="contained" type="submit">
          <ShoppingCart style={{ fontSize: 18, marginRight: 10 }} />
          Ir Para Confirmação
        </ColorButton>
      </FormWrapper>
    );
  } else {
    return (
      <FormWrapper onSubmit={(event) => store(event)}>
        <h2>Confirme seus dados para finalizar o pedido</h2>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Empresa"}
              name={"company_name"}
              id={"company_name"}
              onChange={handleChange("company_name")}
              value={values.company_name}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Serviço Solicitado"}
              name={"initials"}
              id={"initials"}
              onChange={handleChange("initials")}
              value={'Aquisição de Troco'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Loja"}
              name={"store_id"}
              id={"store_id"}
              onChange={handleChange("store_id")}
              value={values.store_id}
              select
              disabled
            >
              {props.company.stores.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name} - {option.initials}
                </MenuItem>
              ))}
            </TextFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Data de Preferência"}
              name={"preferred_date"}
              id={"preferred_date"}
              onChange={handleChange("preferred_date")}
              value={values.preferred_date}
              disabled            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <TextFieldWrapper
              label={"Observações"}
              name={"observations"}
              id={"observations"}
              onChange={handleChange("observations")}
              value={values.observations}
              disabled            
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Informe quantos milheiros deseja para cada moeda:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,05"}
              name={"quantity_coin_5"}
              id={"quantity_coin_5"}
              onChange={handleChange("quantity_coin_5")}
              value={values.quantity_coin_5}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,10"}
              name={"quantity_coin_10"}
              id={"quantity_coin_10"}
              onChange={handleChange("quantity_coin_10")}
              value={values.quantity_coin_10}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,25"}
              name={"quantity_coin_25"}
              id={"quantity_coin_25"}
              onChange={handleChange("quantity_coin_25")}
              value={values.quantity_coin_25}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 0,50"}
              name={"quantity_coin_50"}
              id={"quantity_coin_50"}
              onChange={handleChange("quantity_coin_50")}
              value={values.quantity_coin_50}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 1,00"}
              name={"quantity_coin_100"}
              id={"quantity_coin_100"}
              onChange={handleChange("quantity_coin_100")}
              value={values.quantity_coin_100}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Informe quantos milheiros deseja para cada cédula:</h4>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 2,00"}
              name={"quantity_cash_2"}
              id={"quantity_cash_2"}
              onChange={handleChange("quantity_cash_2")}
              value={values.quantity_cash_2}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 5,00"}
              name={"quantity_cash_5"}
              id={"quantity_cash_5"}
              onChange={handleChange("quantity_cash_5")}
              value={values.quantity_cash_5}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 10,00"}
              name={"quantity_cash_10"}
              id={"quantity_cash_10"}
              onChange={handleChange("quantity_cash_10")}
              value={values.quantity_cash_10}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextFieldWrapper
              label={"R$ 20,00"}
              name={"quantity_cash_20"}
              id={"quantity_cash_20"}
              onChange={handleChange("quantity_cash_20")}
              value={values.quantity_cash_20}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,05</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,10</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,25</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 0,50</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 1,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{values.quantity_coin_5_string}</TableCell>
                <TableCell align="center">{values.quantity_coin_10_string}</TableCell>
                <TableCell align="center">{values.quantity_coin_25_string}</TableCell>
                <TableCell align="center">{values.quantity_coin_50_string}</TableCell>
                <TableCell align="center">{values.quantity_coin_100_string}</TableCell>
                <TableCell align="right">R$ {values.coin_value_string}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 2,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 5,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 10,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">R$ 20,00</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{values.quantity_cash_2_string}</TableCell>
                <TableCell align="center">{values.quantity_cash_5_string}</TableCell>
                <TableCell align="center">{values.quantity_cash_10_string}</TableCell>
                <TableCell align="center">{values.quantity_cash_20_string}</TableCell>
                <TableCell align="right">R$ {values.cash_value_string}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h4>Valores Totais:</h4>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Total em Moedas</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Total em Cédulas</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Valor Solicitado</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Valor da Entrega</TableCell>
                <TableCell style={{fontWeight: 'bold', background: '#ddd'}} align="center">Valor do Processamento da Moeda</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">R$ {values.coin_value_string}</TableCell>
                <TableCell align="center">R$ {values.cash_value_string}</TableCell>
                <TableCell align="center">R$ {values.requested_value_string}</TableCell>
                <TableCell align="center">R$ {values.coin_delivery_fee_paid}</TableCell>
                <TableCell align="center">R$ {values.coin_per_thousand_fee_paid}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3} style={{padding: '20px 0'}}>
          <Grid item xs={12}>
            <CheckboxWrapper
              label={<>Li e Aceito os <a href="https://cdn.zinnerlog.com.br/termos-condicoes.pdf" target="_blank">Termos de Serviço</a>.</>}
              name={"accept"}
              id={"accept"}
              onChange={handleChange("accept")}
              value={values.accept}
              checked={values.accept}
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ColorButton variant="contained" type="submit">
              <ShoppingCart style={{ fontSize: 18, marginRight: 10 }} />
              Confirmar Pedido
            </ColorButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BackButton variant="contained" type={'button'} onClick={() => setStep(1)}>
              <ArrowBackIos style={{ fontSize: 18, marginRight: 10 }} />
              Editar Pedido
            </BackButton>
          </Grid>
        </Grid>
      </FormWrapper>
    );
  }
};
export default SellingAdd;
