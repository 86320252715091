import React from "react";
import { useRecoilState } from "recoil";

// @material-ui components
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import {
  IconButton,
  Grow,
  GrowProps,
  makeStyles,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { amber, green } from "@material-ui/core/colors";

import { snackBarState } from "../../GlobalAtoms";

const variantIcon: any = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function GrowTransition(props: JSX.IntrinsicAttributes & GrowProps) {
  return <Grow {...props} />;
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconInformative: {
    fontSize: 20,
    marginRight: 10,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const SnackbarAlert: React.FC = () => {
  const classes: any = useStyles();

  const [snackBarValues, setSnackBarState] = useRecoilState(snackBarState);

  const Icon = variantIcon[snackBarValues.type];

  const HandleClose = () => {
    setSnackBarState({
      open: false,
      type: snackBarValues.type,
      message: snackBarValues.message,
    });
  };

  return (
    <Snackbar
      open={snackBarValues.open}
      onClose={HandleClose}
      TransitionComponent={GrowTransition}
      autoHideDuration={6000}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <SnackbarContent
        className={classes[snackBarValues.type]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.iconInformative} />{" "}
            {snackBarValues.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={HandleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default SnackbarAlert;
