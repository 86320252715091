import React from "react";

// @material-ui components
import {
  Backdrop,
  createStyles,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { useRecoilValue } from "recoil";
import { loadingOverlayState } from "../../GlobalAtoms";

const useStyles = makeStyles(
  createStyles({
    backdrop: {
      zIndex: 1301,
      color: "#fff",
    },
    loadingIcon: {
      animationDuration: "550ms",
      color: grey[300],
    },
  })
);

const LoadingOverlay = () => {
  const classes = useStyles();

  const loadingOverlay = useRecoilValue(loadingOverlayState);

  return (
    <>
      {loadingOverlay && (
        <Backdrop
          className={classes.backdrop}
          open={loadingOverlay}
          transitionDuration={0}
        >
          <CircularProgress
            className={classes.loadingIcon}
            size={120}
            disableShrink
            color="inherit"
          />
        </Backdrop>
      )}
    </>
  );
};

export default LoadingOverlay;
