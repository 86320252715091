import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import clsx from "clsx";

// @material-ui components
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  Toolbar,
  Grid,
  Box,
  AppBar,
  CssBaseline,
  IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Menu as MenuIcon } from "@material-ui/icons";

import routes from "../routes";
import LoadingOverlay from "../components/Layouts/LoadingOverlay";
import SnackbarAlert from "../components/Layouts/SnackbarAlert";
import HandleError from "../components/Layouts/HandleError";
import DialogWrapper from "../components/Layouts/DialogWrapper";
import ConfirmationDialogWrapper from "../components/Layouts/ConfirmationDialogWrapper";
import AppBarMenu from "../components/Layouts/AppBarMenu";
import DrawerWrapper from "../components/Layouts/DrawerWrapper";
import { drawerWidth } from "../settings";

const dashboardStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerToggle: {
    color: grey[200],
    padding: 6,
  },
  drawerToggleIcon: {
    height: 23,
    width: 23,
  },
  appBar: {
    background: "#06265f",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: theme.mixins.toolbar,
  toolBarMain: {
    minHeight: 25,
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    marginLeft: -drawerWidth,
    paddingTop: 35,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const switchRoutes = () => {
  let routesElements: any[] = [];
  for (const route of routes) {
    if (route.layout === "/dashboard") {
      if (route.display === "menu" && typeof route.links !== "undefined") {
        for (const link of route.links) {
          if (typeof link.component !== "undefined") {
            routesElements = [
              ...routesElements,
              { path: route.layout + link.path + (link.params ? link.params : ""), component: link.component },
            ];
          }
        }
      } else {
        routesElements = [
          ...routesElements,
          { path: route.layout + route.path, component: route.component },
        ];
      }
    }
  }

  return (
    <Switch>
      {routesElements.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      <Redirect from="/dashboard" to="/dashboard/clients" />
    </Switch>
  );
};

const Dashboard: React.FC = () => {
  const customClasses = dashboardStyle();

  const [open, setOpen] = useState(false);
  const [topBarItemsOpen, setTopBarItemsOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    if (open) {
      window.setTimeout(() => {
        setTopBarItemsOpen(!open);
      }, 200);
    } else {
      setTopBarItemsOpen(!open);
    }
  };

  return (
    <div className={customClasses.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(customClasses.appBar, {
          [customClasses.appBarShift]: open,
        })}
      >
        <Toolbar className={customClasses.toolBarMain}>
          <IconButton
            aria-label="open-drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={customClasses.drawerToggle}
          >
            <MenuIcon className={customClasses.drawerToggleIcon} />
          </IconButton>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <Box textAlign="right">
                <Grid item xs={12} sm={12}>
                  <Box textAlign="right">
                    <AppBarMenu topBarItemsOpen={topBarItemsOpen} />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerWrapper open={open} />
      <main
        className={clsx(customClasses.content, {
          [customClasses.contentShift]: open,
        })}
      >
        {switchRoutes()}
        <LoadingOverlay />
        <SnackbarAlert />
        <DialogWrapper />
        <ConfirmationDialogWrapper />
        <HandleError />
      </main>
    </div>
  );
};

export default Dashboard;
