const HandleChange = (name: string, event: any) => {

  let value = event.target.value;

  if(event.target.type === 'checkbox') {
    value = event.target.checked;
  }

  return {
    name: name,
    value: value,
  }
}
export default HandleChange
